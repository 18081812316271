import React, { useEffect, useRef, useState } from "react";
import Assets from "../../Layout/assets";
import styles from "./footer.module.scss";
import Icon from "../../Layout/icons/icons";
import FooterMobile from "../FooterMobile/FooterMobile";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import fetchData from "../../utils/WebService";

export default function Index() {
  const [isLoading, setIsLoading] = useState(false);
  const [quickLink, setQuickLink] = useState([]);
  const [usefulLink, setUsefulLink] = useState([]);
  const [email, setEmail] = useState("");
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const submitData = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!validateEmail(email)) {
      toast.error("Invalid email address!", { position: "bottom-right" });
      return;
    }
    try {
      const postData = {
        _id: "6566d0a2d75c1349b586da94",
        emailId: email,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_NEWSLETTER}`,
        postData
      );
      if (response?.data) {
        toast.success("Subscribed!", { position: "bottom-right" });
        setEmail("");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error(error?.response?.data?.message, { position: "bottom-right" });
    }
  };

  const emailSubmit = (event) => {
    setEmail(event.target?.value);
  };

  useEffect(() => {
    const fetchDataAsync = async () => {
      setIsLoading(true);
      const newData = await fetchData("xlri", "footer-quick-links");
      if (newData) {
        const newArray = newData.map((item) => {
          const fields = item.fields.reduce((acc, field) => {
            acc[field.fieldName] = field.value;
            return acc;
          }, {});

          return { _id: item._id, ...fields };
        });
        setQuickLink(newArray);
        setIsLoading(false);
      }
    };
    fetchDataAsync();
  }, []);

  useEffect(() => {
    const fetchDataAsyncTwo = async () => {
      setIsLoading(true);
      const newData = await fetchData("xlri", "footer-useful-links");
      if (newData) {
        const newArray = newData.map((item) => {
          const fields = item.fields.reduce((acc, field) => {
            acc[field.fieldName] = field.value;
            return acc;
          }, {});

          return { _id: item._id, ...fields };
        });
        setUsefulLink(newArray);
        setIsLoading(false);
      }
    };
    fetchDataAsyncTwo();
  }, []);

  const d = new Date();
  let year = d.getFullYear();

  useEffect(() => {
    const url = "/pgdhrm-chro";

    if (window.location.pathname === url) {
      // Function to load LinkedIn Insight Tag script
      const loadLinkedInScript = () => {
        if (!window.lintrk) {
          window._linkedin_data_partner_ids =
            window._linkedin_data_partner_ids || [];
          window._linkedin_data_partner_ids.push("3285946");

          const script1 = document.createElement("script");
          script1.type = "text/javascript";
          script1.text = `
            _linkedin_partner_id = "3285946";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);
          `;
          document.head.appendChild(script1);

          const script2 = document.createElement("script");
          script2.type = "text/javascript";
          script2.async = true;
          script2.src =
            "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          document.head.appendChild(script2);
        }

        const noscript = document.createElement("noscript");
        noscript.innerHTML = `
          <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=3285946&fmt=gif" />
        `;
        document.body.appendChild(noscript);
      };

      loadLinkedInScript();

      return () => {
        // Cleanup script and noscript
        const scripts = document.querySelectorAll(
          'script[src="https://snap.licdn.com/li.lms-analytics/insight.min.js"]'
        );
        const noscripts = document.querySelectorAll("noscript");

        scripts.forEach((script) => document.head.removeChild(script));
        noscripts.forEach((noscript) => document.body.removeChild(noscript));
      };
    }
  }, []);

  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className={styles.footerWrapper}>
          <div className="row row-cols-lg-2">
            <div className={styles.whatsappWrapper}>
              <figure>
                <img src={Assets.whatsappqr} alt="" />
              </figure>
              <h4 className="h4">Scan to chat with WhatsApp</h4>
            </div>
            <div className={styles.newsletterWrapper}>
              <p>Subscribe Our Newsletter</p>
              <div className={styles.newsletterInput}>
                <form>
                  <input
                    type="text"
                    placeholder="Your@email.com"
                    required
                    onChange={(event) => emailSubmit(event)}
                    value={email}
                  ></input>
                  <button type="submit" onClick={(event) => submitData(event)}>
                    <Icon icon="slider-arrow" size={21} color="#B2C950" />
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="d-none d-xl-block">
            <div className={`row row-cols-lg-3 ${styles.footerLinksRow}`}>
              {quickLink?.length > 0 && (
                <div className={styles.quicklinkswrap}>
                  <h5 className="h5">Quick Links</h5>
                  <ul>
                    {quickLink?.map((obj, idx) => (
                      <li key={idx}>
                        <Link
                          to={`/${obj?.link}`}
                          target={obj?.newTab === "true" ? "_blank" : "_self"}
                        >
                          <button className={styles.footerListBtn}>
                            {obj?.title}
                          </button>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {usefulLink?.length > 0 && (
                <div className={styles.usefulswrap}>
                  <h5 className="h5">Useful Links</h5>
                  <ul>
                    {usefulLink?.map((obj, idx) => (
                      <li key={idx}>
                        <Link
                          to={`/${obj?.link}`}
                          target={obj?.newTab === "true" ? "_blank" : "_self"}
                        >
                          <button className={styles.footerListBtn}>
                            {obj?.title}
                          </button>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className={styles.contactInfo}>
                <h5 className="h5">Contact info</h5>
                <div className={styles.phoneWrapper}>
                  <a href="tel:+91 6576 653333">+91 657 665 3333</a>
                  <a href="mailto:info@xlri.ac.in">info@xlri.ac.in</a>
                </div>
                <div className={styles.addressWrapper}>
                  <p className="p-18">
                    XLRI <br></br>C. H. Area (East)<br></br> Jamshedpur - 831001
                    <br></br> Jharkhand, India.
                  </p>
                  <Link className="linkWrapper" to="/reaching-xlri">
                    <span className="a-18">Get Direction</span>
                    <Icon
                      icon="arrow-right-with-cut"
                      size={10}
                      color="#B2C950"
                    />
                  </Link>
                </div>
                <div className={styles.socialmediaWrapper}>
                  <h5 className="h5">Follow us</h5>
                  <ul className={styles.socialmediaicons}>
                    <li>
                      <a
                        href="https://www.facebook.com/XLRI.Jamshedpur"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <Icon icon="facebook" size={17} color="#FFFFFF" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/xlrijamshedpur"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <Icon icon="twitter" size={17} color="#FFFFFF" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/xlrijamshedpur/"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <Icon icon="instagram" size={17} color="#FFFFFF" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/school/xlri"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <Icon icon="linkedin" size={17} color="#FFFFFF" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/user/XLRIBRAND"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <Icon icon="youtube" size={17} color="#FFFFFF" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`d-xl-none ${styles.mobileFooterWrapper}`}>
          <FooterMobile quickLink={quickLink} usefulLink={usefulLink} />
        </div>
        <div className={styles.footerEnd}>
          <p>Copyright @ {year}, XLRI. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}
