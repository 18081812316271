import React, { useContext } from "react";
import styles from "./SchoolOfBusinessNew.module.scss";
import HeaderContext from "../../context/Context-header";
import MetaDataContext from "../../context/MetaDataContext";
import MetaData from "../../helpers/MetaData";
import BreadCrumb from "../breadcrumb/BreadCrumb";
import studentsImage from "../../assets/images/acedemic-programmmes-school-of-business/studentImage1.png";
import XatLogo from "../../assets/images/acedemic-programmmes-school-of-business/xatLogoNew.png";
import GmatLogo from "../../assets/images/acedemic-programmmes-school-of-business/GmatLogoNew.png";
import GreLogo from "../../assets/images/acedemic-programmmes-school-of-business/GreLogoNew.png";
import Icon from "../../Layout/icons/icons";
import studentGroup from "../../assets/images/acedemic-programmmes-school-of-business/studentsPgdgm.png";
import RelatedCourses from "../CommonComponent/RelatedCourses";
import studentImage2 from "../../assets/images/acedemic-programmmes-school-of-business/studImage2.png";
import studProfile from "../../assets/images/acedemic-programmmes-school-of-business/userImg.png";
import PlacementCommitteeMailIcon from "../../assets/icons/icon-mail.svg";
import PlacementCommitteePhoneIcon from "../../assets/icons/icon-phone.svg";
import LinkedInImg from "../../assets/images/career-transformation/linked-in-black.png";
import BatchProfile from "../../assets/images/acedemic-programmmes-school-of-business/batchProfilePgdgm.png";

const SchoolOfBusinessNew = () => {
  const { HeaderComponent } = useContext(HeaderContext);
  const metaInfo = useContext(MetaDataContext);

  const navigateToPlacement = () => {
    window.open("/placement-overview", "_blank");
  };

  return (
    <div>
      <MetaData metaData={metaInfo} />
      <HeaderComponent />
      <BreadCrumb />
      <div className={styles.sectionOne}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2>Postgraduate diploma in General Management (PGDGM)</h2>
              <div className="row">
                <div className="col-4">
                  <img src={XatLogo} alt="XAT" />
                </div>
                <div className="col-4">
                  <img src={GmatLogo} alt="GMAT" />
                </div>
                <div className="col-4">
                  <img src={GreLogo} alt="ETS GRE" />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 mt-2">
                  <p>
                    In the journey of transformation, XLRI didn’t just follow
                    others but opened new vistas of management styles. For XLRI,
                    catering to the needs of the aspiring business leaders,
                    industry and the nation is an important guiding philosophy.
                    The teaching methodology empowers students to think beyond
                    the possible, providing the guidance and industry related
                    learning, thereby enabling students to become the future
                    leaders with a human touch.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img
                src={studentsImage}
                alt="Postgraduate diploma in General Management (PGDGM)"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <p>
                XLRI offers a 18 months full-time residential management program
                PGDM (GM), previously known as GMP. Incoming students having a
                minimum of three years of experience in varied fields like
                IT/ITeS, Energy/Power, Automobile, Manufacturing etc are
                crystallized into modern corporate warriors imbibed with a human
                touch. The course enables students to grasp the latest in
                management theory, broadens perspectives and launches them into
                new avenues of problem solving. XLRI’s deep commitment to nation
                building is demonstrated year on year as the program nurtures
                socially responsible change agents transforming the business
                landscape of modern India.
              </p>
              <p>
                This one of the flagship programs offered by XLRI and this
                program has received various prestigious accreditations
                including AMBA, AICTE etc. The superlative course structure
                combined with the substantial work-experience of the
                participants makes them highly sought after by the industry
                post-graduation.
              </p>
            </div>
          </div>
          <div className="col-xl-12">
            <div className={`mt-1 d-xl-flex d-block  ${styles.ButtonWrap}`}>
              <button
                className={`btnwrap ${styles.BtnColorBlue} ${styles.BtnNewSpace}`}
              >
                <span className="a-18">
                  <a href="https://xatonline.in/" target="_blank">
                    Register for XAT & XLRI
                  </a>
                </span>
                <Icon icon="arrow-right-with-cut" size={10} color="#B2C950" />
              </button>
              <button
                className={`btnwrap ${styles.BtnColorBlue} ${styles.BtnNewSpace}`}
              >
                <span className="a-18">
                  <a href="https://xatonline.in/xlri" target="_blank">
                    Register through GMAT/GRE
                  </a>
                </span>
                <Icon icon="arrow-right-with-cut" size={10} color="#B2C950" />
              </button>
            </div>
            <br />
          </div>
          <div className="col-xl-12">
            <img src={studentGroup} />
          </div>
        </div>
      </div>

      <div className={styles.sectionTwo}>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className={styles.mainTitle}>
                <h2>
                  The GMP interviews will be conducted in multiple rounds.
                </h2>
              </div>
            </div>
            <div className={`col-md-7 ${styles.mainContent}`}>
              <ul>
                <li>
                  The first round of GMP interviews for GMAT candidates will
                  commence in <span>September 2024</span>.{" "}
                </li>
                <li>
                  The second round of GMP interviews for GMAT candidates will
                  commence in <span>October 2024</span>.
                </li>
                <li>
                  The third round of GMP interviews for GMAT candidates will
                  commence in <span>November 2024</span>.
                </li>
                <li>
                  The fourth round of GMP interviews for GMAT candidates will
                  commence in <span>December 2024</span>.
                </li>
                <li>
                  The final round of GMP interviews for XAT candidates will
                  commence in first week of <span>February 2025</span>.
                </li>
                <li>
                  The programme will commence in <span>April 2025</span>.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.sectionThree}>
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className={styles.tabMainSec}>
                <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      className="nav-link  active"
                      id="nav-home-tab-two"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home-two"
                      type="button"
                      role="tab"
                      aria-controls="nav-home-two"
                      aria-selected="true"
                    >
                      Academics
                    </button>
                    <button
                      className="nav-link "
                      id="nav-profile-tab-two"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-profile-two"
                      type="button"
                      role="tab"
                      aria-controls="nav-profile-two"
                      aria-selected="false"
                    >
                      Profile
                    </button>
                    <button
                      className="nav-link"
                      id="nav-vil-query-two"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-vil-two"
                      type="button"
                      role="tab"
                      aria-controls="nav-vil-two"
                      aria-selected="false"
                    >
                      Admission Procedure
                    </button>
                    <button
                      className="nav-link"
                      id="nav-vil-query-three"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-vil-three"
                      type="button"
                      role="tab"
                      aria-controls="nav-vil-three"
                      aria-selected="false"
                      onClick={() => navigateToPlacement()}
                    >
                      Placement
                    </button>
                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-home-two"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab-two"
                    tabIndex="0"
                  >
                    <h5>Curriculum</h5>
                    <div className="row">
                      <div className="col-md-7">
                        <p>
                          This year the MBA program comprises of seven terms
                          with duration of all terms ranges between 5 to 13
                          weeks depending on number of subjects in each term.
                          Every term ends with an exam. The first 3 Terms are
                          broadly focused on to build rudimentary management
                          concepts across all disciplines. The 15 core courses
                          offered during this time includes subjects from
                          finance, economics, marketing, operations, strategy,
                          business ethics and sustainable development. The
                          remaining Terms of the program can be customized,
                          allowing you to choose approximately 20 different
                          electives that support your career and learning
                          objectives. XLRI offers a rich and varied choice of
                          electives for you to choose from - over 53 altogether.
                        </p>
                        <h5>Pre-term</h5>
                        <p>
                          Three days of introductory course, which lay the
                          foundation for all courses to be, is taught during the
                          18 month full-time MBA. The objective of the pre-term
                          is to normalize students coming from different
                          backgrounds.
                        </p>
                      </div>
                      <div className="col-md-5">
                        <img
                          src={studentImage2}
                          alt="Postgraduate diploma in General Management (PGDGM)"
                        />
                      </div>
                    </div>
                    <h5>Term-wise break-up</h5>
                    <div className={`row ${styles.termWiseMain}`}>
                      <div className={`col-lg-6 ${styles.termWiseSub}`}>
                        <div className={styles.termWiseContent}>
                          <h5>
                            Term <strong>01</strong>
                          </h5>
                          <ul>
                            <li>
                              <span>Marketing Management-I</span>
                              <span>3</span>
                            </li>
                            <li>
                              <span>Managerial Communication-I</span>
                              <span>3</span>
                            </li>
                            <li>
                              <span>Managerial Economics</span>
                              <span>2.5</span>
                            </li>
                            <li>
                              <span>
                                Managing Human Behaviour at Work (OB-I)
                              </span>
                              <span>2</span>
                            </li>
                            <li>
                              <span>
                                Quantitative Techniques for Managerial
                                Decisions-I
                              </span>
                              <span>2</span>
                            </li>
                            <li>
                              <span>Legal Environment of Business</span>
                              <span>2.5</span>
                            </li>
                            <li>
                              <span>Marketing Management-I</span>
                              <span>2</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className={`col-lg-6 ${styles.termWiseSub}`}>
                        <div className={styles.termWiseContent}>
                          <h5>
                            Term <strong>02</strong>
                          </h5>
                          <ul>
                            <li>
                              <span>Marketing Management-II</span>
                              <span>2</span>
                            </li>
                            <li>
                              <span>Macroeconomic Theory and Policy</span>
                              <span>2.5</span>
                            </li>
                            <li>
                              <span>Financial Management-I</span>
                              <span>2</span>
                            </li>
                            <li>
                              <span>Human Resource Management</span>
                              <span>2</span>
                            </li>
                            <li>
                              <span>Management Accounting-II</span>
                              <span>2</span>
                            </li>
                            <li>
                              <span>
                                Managing Human Behaviour at Work (OB-II)
                              </span>
                              <span>2</span>
                            </li>
                            <li>
                              <span>Competitive Strategy</span>
                              <span>2</span>
                            </li>
                            <li>
                              <span>
                                Quantitative Techniques for Managerial
                                Decisions-II
                              </span>
                              <span>2</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className={`col-lg-6 ${styles.termWiseSub}`}>
                        <div className={styles.termWiseContent}>
                          <h5>
                            Term <strong>03</strong>
                          </h5>
                          <ul>
                            <li>
                              <span>Operations Research</span>
                              <span>2</span>
                            </li>
                            <li>
                              <span>Managerial Ethics2</span>
                              <span>2</span>
                            </li>
                            <li>
                              <span>
                                MIS: Technical and Social Perspectives(Includes
                                Spreadsheet Modeling )
                              </span>
                              <span>3</span>
                            </li>
                            <li>
                              <span>
                                Corporate Strategy and Strategy Implementation
                              </span>
                              <span>3</span>
                            </li>
                            <li>
                              <span>Managerial Communication-II</span>
                              <span>1.5</span>
                            </li>
                            <li>
                              <span>Industrial Relations</span>
                              <span>2</span>
                            </li>
                            <li>
                              <span>
                                Production and Operations Management-I
                              </span>
                              <span>2</span>
                            </li>
                            <li>
                              <span>Financial Management-II</span>
                              <span>2</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className={`col-lg-6 ${styles.termWiseSub}`}>
                        <div className={styles.termWiseContent}>
                          <h5>
                            Term <strong>04</strong>
                          </h5>
                          <ul>
                            <li>
                              <span>
                                Production and Operations Management-II
                              </span>
                              <span>2</span>
                            </li>
                            <li>
                              <span>Digital transformation</span>
                              <span>1.5</span>
                            </li>
                            <li>
                              <span>
                                Introduction to Sustainable Development &
                                Corporate Sustainability
                              </span>
                              <span>2</span>
                            </li>
                            <li>
                              <span>Electives</span>
                              <span>6</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <h5>Electives will be offered in Term V & Term VI</h5>
                    <h3>List of Electives Offered</h3>
                    <div className={styles.tableMain}>
                      <table>
                        <thead>
                          <tr>
                            <th>General Management</th>
                            <th>General Management</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Communicating Critical Decisions</td>
                            <td>Strategies of Cooperation</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className={styles.tableMain}>
                      <table>
                        <thead>
                          <tr>
                            <th>Strategic Management</th>
                            <th>Information Systems</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Balanced Scorecard</td>
                            <td>Big Data Analytics</td>
                          </tr>
                          <tr>
                            <td>Balanced Scorecard</td>
                            <td>Big Data Analytics</td>
                          </tr>
                          <tr>
                            <td>Entrepreneurship and New Ventures</td>
                            <td>Business Intelligence and Data Mining</td>
                          </tr>
                          <tr>
                            <td>
                              Foundations of Management Consulting Practice
                            </td>
                            <td>Business Modelling Using Spreadsheets</td>
                          </tr>
                          <tr>
                            <td>International Management</td>
                            <td>IS Strategy</td>
                          </tr>
                          <tr>
                            <td>Managing Innovation</td>
                            <td>
                              Social Mobile Analytics and Cloud - The Emerging
                              Business Accelerator
                            </td>
                          </tr>
                          <tr>
                            <td>Managing Strategic Transformation</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className={styles.tableMain}>
                      <table>
                        <thead>
                          <tr>
                            <th>Production, Operations & Decision Sciences</th>
                            <th>Finance</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Demand Forecasting</td>
                            <td>Advanced Corporate Finance</td>
                          </tr>
                          <tr>
                            <td>
                              Introduction to Enterprise Resource Planning
                            </td>
                            <td>Business Analysis and Valuation</td>
                          </tr>
                          <tr>
                            <td>Introducing Supply Chain Analytics</td>
                            <td>Capital Expenditure Planning and Control</td>
                          </tr>
                          <tr>
                            <td>Operations Planning and Control</td>
                            <td>Financial Modelling Using Excel</td>
                          </tr>
                          <tr>
                            <td>Project Planning and Control</td>
                            <td>Financial Risk Management</td>
                          </tr>
                          <tr>
                            <td>Quality Management</td>
                            <td>International Financial Management</td>
                          </tr>
                          <tr>
                            <td>Quality Management</td>
                            <td>Investments</td>
                          </tr>
                          <tr>
                            <td>Supply Chain Management</td>
                            <td>
                              Mergers, Acquisition and Corporate Restructuring
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>Strategic Cost Management</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className={styles.tableMain}>
                      <table>
                        <thead>
                          <tr>
                            <th>Human Resource Management</th>
                            <th>Organization Behaviour</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>HR Issues in Mergers and Acquisitions</td>
                            <td>
                              Managing Competencies and Performance in
                              Organisations
                            </td>
                          </tr>
                          <tr>
                            <td>Performance Management and Appraisal</td>
                            <td>
                              Thriving in Political Environment [With Integrity
                              & Ethics]
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Tools and Techniques for Executive Development
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Compensation and Reward Management</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Balanced Scorecard</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className={styles.tableMain}>
                      <table>
                        <thead>
                          <tr>
                            <th>Marketing</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Advertising and Integrated Brand Promotion</td>
                            <td>Business-to-Business Marketing</td>
                          </tr>
                          <tr>
                            <td>Consumer Behaviour</td>
                            <td>Competition and Globalization</td>
                          </tr>
                          <tr>
                            <td>Entrepreneurship and New Ventures</td>
                            <td>
                              Experiential Workshop on Selling and Negotiating
                              Tactics
                            </td>
                          </tr>
                          <tr>
                            <td>Internet Marketing</td>
                            <td>Managing Corporate Reputation</td>
                          </tr>
                          <tr>
                            <td>Marketing Analytics</td>
                            <td>Marketing Strategy and Implementation</td>
                          </tr>
                          <tr>
                            <td>Product and Brand Management</td>
                            <td>Retail Management</td>
                          </tr>
                          <tr>
                            <td>Sales and Distribution Management</td>
                            <td>Services Marketing Management</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <h3>Leadership & Adventure</h3>
                    <p>
                      The essence of the adventure trip is to experience the
                      thrill and power of team spirit. This fun trip deepens the
                      belief in team power. Students experience a rush of
                      adrenalin and the two and half days can be defined as fun
                      and frolic. The trip tests a student’s physical, mental,
                      strategy building, resource/energy management and team
                      building skills. This program helps one understand his/her
                      limits and then go way past them. The philosophy of
                      stretching beyond the limit is what XLRI preaches. XLRI’s
                      methodology of teaching, which is about reinventing
                      oneself continuously, is put to practice in this trip.
                    </p>

                    <h3>Rural Exposure</h3>
                    <p>
                      The intent of this program is to train students to
                      understand the problems of rural India and address them in
                      a better way, it is mandatory for students to spend a few
                      days in the rural parts of India.
                    </p>
                    <p>
                      Under the Village Exposure Program, students of the PGDM
                      (GM) have to identify the problems of the people of a
                      particular village and then suggest solutions. Students
                      will then have to design business modules. The rural
                      exposure Program will help the future managers to
                      understand the needs of the local people and provide
                      solutions to their problems.
                    </p>

                    <div className={styles.ambassdorContainer}>
                      <section className={styles.CardSection}>
                        <div className="container">
                          <h1>Campus Ambassadors</h1>
                          <div className={styles.SliderContent}>
                            <ul>
                              <li>
                                <div className={styles.foundingBgTxt}>
                                  <div className={styles.foundingImgWrapper}>
                                    <img src={studProfile} alt="home img" />
                                  </div>
                                  <div className={styles.TextCls}>
                                    <a
                                      href="https://www.linkedin.com/in/simarjeet1/"
                                      target="_blank"
                                    >
                                      <div className={styles.LinkedImgStyle}>
                                        <img
                                          src={LinkedInImg}
                                          alt="About-slider"
                                        />
                                      </div>
                                    </a>
                                    <h2>Simarjeet Singh Dua</h2>

                                    <div className={styles.CardSpace}>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteePhoneIcon}
                                          alt="Placement Committee"
                                        />
                                        <h6>+91 9891311132</h6>
                                      </div>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteeMailIcon}
                                          alt="Placement Committee"
                                        />
                                        <div className={styles.LeftSpace}>
                                          <h6>g22043@astra.xlri.ac.in</h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className={styles.foundingBgTxt}>
                                  <div className={styles.foundingImgWrapper}>
                                    <img src={studProfile} alt="home img" />
                                  </div>
                                  <div className={styles.TextCls}>
                                    <a
                                      href="https://www.linkedin.com/in/surbhitp"
                                      target="_blank"
                                    >
                                      <div className={styles.LinkedImgStyle}>
                                        <img
                                          src={LinkedInImg}
                                          alt="About-slider"
                                        />
                                      </div>
                                    </a>
                                    <h2>Surbhit Parandiyal</h2>
                                    <div className={styles.CardSpace}>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteePhoneIcon}
                                          alt="Placement Committee"
                                        />
                                        <h6>+91 8093676974</h6>
                                      </div>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteeMailIcon}
                                          alt="Placement Committee"
                                        />
                                        <div className={styles.LeftSpace}>
                                          <h6>G22108@astra.xlri.ac.in</h6>
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className={styles.foundingBgTxt}>
                                  <div className={styles.foundingImgWrapper}>
                                    <img src={studProfile} alt="home img" />
                                  </div>
                                  <div className={styles.TextCls}>
                                    <a
                                      href="https://www.linkedin.com/in/1ambharath"
                                      target="_blank"
                                    >
                                      <div className={styles.LinkedImgStyle}>
                                        <img
                                          src={LinkedInImg}
                                          alt="About-slider"
                                        />
                                      </div>
                                    </a>
                                    <h2>Bharath R</h2>
                                    <div className={styles.CardSpace}>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteePhoneIcon}
                                          alt="Placement Committee"
                                        />
                                        <h6>+91 7696610609</h6>
                                      </div>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteeMailIcon}
                                          alt="Placement Committee"
                                        />
                                        <div className={styles.LeftSpace}>
                                          <h6>G22013@astra.xlri.ac.in</h6>
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className={styles.foundingBgTxt}>
                                  <div className={styles.foundingImgWrapper}>
                                    <img src={studProfile} alt="home img" />
                                  </div>
                                  <div className={styles.TextCls}>
                                    <a
                                      href="https://www.linkedin.com/in/sanjeevani-312887121"
                                      target="_blank"
                                    >
                                      <div className={styles.LinkedImgStyle}>
                                        <img
                                          src={LinkedInImg}
                                          alt="About-slider"
                                        />
                                      </div>
                                    </a>
                                    <h2>Sanjeevani</h2>
                                    <div className={styles.CardSpace}>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteePhoneIcon}
                                          alt="Placement Committee"
                                        />
                                        <h6>+91 8545957829</h6>
                                      </div>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteeMailIcon}
                                          alt="Placement Committee"
                                        />
                                        <div className={styles.LeftSpace}>
                                          <h6>G22036@astra.xlri.ac.in</h6>
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className={styles.foundingBgTxt}>
                                  <div className={styles.foundingImgWrapper}>
                                    <img src={studProfile} alt="home img" />
                                  </div>
                                  <div className={styles.TextCls}>
                                    <a
                                      href="https://www.linkedin.com/in/amit-sunar"
                                      target="_blank"
                                    >
                                      <div className={styles.LinkedImgStyle}>
                                        <img
                                          src={LinkedInImg}
                                          alt="About-slider"
                                        />
                                      </div>
                                    </a>
                                    <h2>Amit Sunar</h2>

                                    <div className={styles.CardSpace}>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteePhoneIcon}
                                          alt="Placement Committee"
                                        />
                                        <h6>+91 9643889181</h6>
                                      </div>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteeMailIcon}
                                          alt="Placement Committee"
                                        />
                                        <div className={styles.LeftSpace}>
                                          <h6>g22004@astra.xlri.ac.in</h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className={styles.foundingBgTxt}>
                                  <div className={styles.foundingImgWrapper}>
                                    <img src={studProfile} alt="home img" />
                                  </div>
                                  <div className={styles.TextCls}>
                                    <a
                                      href="https://www.linkedin.com/in/kajalkumari04/"
                                      target="_blank"
                                    >
                                      <div className={styles.LinkedImgStyle}>
                                        <img
                                          src={LinkedInImg}
                                          alt="About-slider"
                                        />
                                      </div>
                                    </a>
                                    <h2>Kajal Kumari</h2>
                                    <div className={styles.CardSpace}>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteePhoneIcon}
                                          alt="Placement Committee"
                                        />
                                        <h6>+91 7873709563</h6>
                                      </div>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteeMailIcon}
                                          alt="Placement Committee"
                                        />
                                        <div className={styles.LeftSpace}>
                                          <h6>g22022@astra.xlri.ac.in</h6>
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className={styles.foundingBgTxt}>
                                  <div className={styles.foundingImgWrapper}>
                                    <img src={studProfile} alt="home img" />
                                  </div>
                                  <div className={styles.TextCls}>
                                    <a
                                      href="https://www.linkedin.com/in/sumit-mudgal-ba0228a0/"
                                      target="_blank"
                                    >
                                      <div className={styles.LinkedImgStyle}>
                                        <img
                                          src={LinkedInImg}
                                          alt="About-slider"
                                        />
                                      </div>
                                    </a>
                                    <h2>Sumit Mudgal</h2>
                                    <div className={styles.CardSpace}>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteePhoneIcon}
                                          alt="Placement Committee"
                                        />
                                        <h6>+91 8800335407</h6>
                                      </div>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteeMailIcon}
                                          alt="Placement Committee"
                                        />
                                        <div className={styles.LeftSpace}>
                                          <h6>g22106@astra.xlri.ac.in</h6>
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className={styles.foundingBgTxt}>
                                  <div className={styles.foundingImgWrapper}>
                                    <img src={studProfile} alt="home img" />
                                  </div>
                                  <div className={styles.TextCls}>
                                    <a
                                      href="https://www.linkedin.com/in/vivek-bhagat-bb1685ab"
                                      target="_blank"
                                    >
                                      <div className={styles.LinkedImgStyle}>
                                        <img
                                          src={LinkedInImg}
                                          alt="About-slider"
                                        />
                                      </div>
                                    </a>
                                    <h2>Vivek Kumar</h2>
                                    <div className={styles.CardSpace}>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteePhoneIcon}
                                          alt="Placement Committee"
                                        />
                                        <h6>+91 8750974630</h6>
                                      </div>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteeMailIcon}
                                          alt="Placement Committee"
                                        />
                                        <div className={styles.LeftSpace}>
                                          <h6>g22112@astra.xlri.ac.in</h6>
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className={styles.foundingBgTxt}>
                                  <div className={styles.foundingImgWrapper}>
                                    <img src={studProfile} alt="home img" />
                                  </div>
                                  <div className={styles.TextCls}>
                                    <a
                                      href="https://www.linkedin.com/in/nishit-rastogi-b751b3180/"
                                      target="_blank"
                                    >
                                      <div className={styles.LinkedImgStyle}>
                                        <img
                                          src={LinkedInImg}
                                          alt="About-slider"
                                        />
                                      </div>
                                    </a>
                                    <h2>Nishit Rastogi</h2>
                                    <div className={styles.CardSpace}>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteePhoneIcon}
                                          alt="Placement Committee"
                                        />
                                        <h6>+91 8460824002</h6>
                                      </div>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteeMailIcon}
                                          alt="Placement Committee"
                                        />
                                        <div className={styles.LeftSpace}>
                                          <h6>g22028@astra.xlri.ac.in</h6>
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className={styles.foundingBgTxt}>
                                  <div className={styles.foundingImgWrapper}>
                                    <img src={studProfile} alt="home img" />
                                  </div>
                                  <div className={styles.TextCls}>
                                    <a
                                      href="https://www.linkedin.com/in/dilpreet-kaur-112090145/"
                                      target="_blank"
                                    >
                                      <div className={styles.LinkedImgStyle}>
                                        <img
                                          src={LinkedInImg}
                                          alt="About-slider"
                                        />
                                      </div>
                                    </a>
                                    <h2>Dilpreet Kaur</h2>
                                    <div className={styles.CardSpace}>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteePhoneIcon}
                                          alt="Placement Committee"
                                        />
                                        <h6>+91 8447541994</h6>
                                      </div>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteeMailIcon}
                                          alt="Placement Committee"
                                        />
                                        <div className={styles.LeftSpace}>
                                          <h6>g22073@astra.xlri.ac.in</h6>
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className={styles.foundingBgTxt}>
                                  <div className={styles.foundingImgWrapper}>
                                    <img src={studProfile} alt="home img" />
                                  </div>
                                  <div className={styles.TextCls}>
                                    <a
                                      href="https://www.linkedin.com/in/vijayankesh-bariar"
                                      target="_blank"
                                    >
                                      <div className={styles.LinkedImgStyle}>
                                        <img
                                          src={LinkedInImg}
                                          alt="About-slider"
                                        />
                                      </div>
                                    </a>
                                    <h2>Vijayankesh Barier</h2>
                                    <div className={styles.CardSpace}>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteePhoneIcon}
                                          alt="Placement Committee"
                                        />
                                        <h6>+91 8116366695</h6>
                                      </div>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteeMailIcon}
                                          alt="Placement Committee"
                                        />
                                        <div className={styles.LeftSpace}>
                                          <h6>g22054@astra.xlri.ac.in</h6>
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className={styles.foundingBgTxt}>
                                  <div className={styles.foundingImgWrapper}>
                                    <img src={studProfile} alt="home img" />
                                  </div>
                                  <div className={styles.TextCls}>
                                    <a
                                      href="https://www.linkedin.com/in/jeetu-mohan-926ab020/"
                                      target="_blank"
                                    >
                                      <div className={styles.LinkedImgStyle}>
                                        <img
                                          src={LinkedInImg}
                                          alt="About-slider"
                                        />
                                      </div>
                                    </a>
                                    <h2>Jeetu Mohan</h2>
                                    <div className={styles.CardSpace}>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteePhoneIcon}
                                          alt="Placement Committee"
                                        />
                                        <h6>+91 8007446664</h6>
                                      </div>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteeMailIcon}
                                          alt="Placement Committee"
                                        />
                                        <div className={styles.LeftSpace}>
                                          <h6>g22078@astra.xlri.ac.in</h6>
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className={styles.foundingBgTxt}>
                                  <div className={styles.foundingImgWrapper}>
                                    <img src={studProfile} alt="home img" />
                                  </div>
                                  <div className={styles.TextCls}>
                                    <a
                                      href="https://www.linkedin.com/in/pooja-mahindrakar"
                                      target="_blank"
                                    >
                                      <div className={styles.LinkedImgStyle}>
                                        <img
                                          src={LinkedInImg}
                                          alt="About-slider"
                                        />
                                      </div>
                                    </a>
                                    <h2>Pooja Mahindrakar</h2>
                                    <div className={styles.CardSpace}>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteePhoneIcon}
                                          alt="Placement Committee"
                                        />
                                        <h6>+91 7016774168</h6>
                                      </div>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteeMailIcon}
                                          alt="Placement Committee"
                                        />
                                        <div className={styles.LeftSpace}>
                                          <h6>g22030@astra.xlri.ac.in</h6>
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className={styles.foundingBgTxt}>
                                  <div className={styles.foundingImgWrapper}>
                                    <img src={studProfile} alt="home img" />
                                  </div>
                                  <div className={styles.TextCls}>
                                    <a
                                      href="https://www.linkedin.com/in/akshayrmali"
                                      target="_blank"
                                    >
                                      <div className={styles.LinkedImgStyle}>
                                        <img
                                          src={LinkedInImg}
                                          alt="About-slider"
                                        />
                                      </div>
                                    </a>
                                    <h2>Akshay Mali</h2>
                                    <div className={styles.CardSpace}>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteePhoneIcon}
                                          alt="Placement Committee"
                                        />
                                        <h6>+91 9085293321</h6>
                                      </div>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteeMailIcon}
                                          alt="Placement Committee"
                                        />
                                        <div className={styles.LeftSpace}>
                                          <h6>g22061@astra.xlri.ac.in</h6>
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className={styles.foundingBgTxt}>
                                  <div className={styles.foundingImgWrapper}>
                                    <img src={studProfile} alt="home img" />
                                  </div>
                                  <div className={styles.TextCls}>
                                    <a
                                      href="https://www.linkedin.com/in/jyoti-panda"
                                      target="_blank"
                                    >
                                      <div className={styles.LinkedImgStyle}>
                                        <img
                                          src={LinkedInImg}
                                          alt="About-slider"
                                        />
                                      </div>
                                    </a>
                                    <h2>Jyoti Panda</h2>
                                    <div className={styles.CardSpace}>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteePhoneIcon}
                                          alt="Placement Committee"
                                        />
                                        <h6>+91 7749842607</h6>
                                      </div>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteeMailIcon}
                                          alt="Placement Committee"
                                        />
                                        <div className={styles.LeftSpace}>
                                          <h6>g22079@astra.xlri.ac.in</h6>
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className={styles.foundingBgTxt}>
                                  <div className={styles.foundingImgWrapper}>
                                    <img src={studProfile} alt="home img" />
                                  </div>
                                  <div className={styles.TextCls}>
                                    <a
                                      href="https://www.linkedin.com/in/kumgoutam"
                                      target="_blank"
                                    >
                                      <div className={styles.LinkedImgStyle}>
                                        <img
                                          src={LinkedInImg}
                                          alt="About-slider"
                                        />
                                      </div>
                                    </a>
                                    <h2>Kumar Goutam</h2>
                                    <div className={styles.CardSpace}>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteePhoneIcon}
                                          alt="Placement Committee"
                                        />
                                        <h6>+91 9900486995</h6>
                                      </div>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteeMailIcon}
                                          alt="Placement Committee"
                                        />
                                        <div className={styles.LeftSpace}>
                                          <h6>g22023@astra.xlri.ac.in</h6>
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className={styles.foundingBgTxt}>
                                  <div className={styles.foundingImgWrapper}>
                                    <img src={studProfile} alt="home img" />
                                  </div>
                                  <div className={styles.TextCls}>
                                    <a
                                      href="https://www.linkedin.com/in/ankeet-rai"
                                      target="_blank"
                                    >
                                      <div className={styles.LinkedImgStyle}>
                                        <img
                                          src={LinkedInImg}
                                          alt="About-slider"
                                        />
                                      </div>
                                    </a>
                                    <h2>Ankeet Rai</h2>
                                    <div className={styles.CardSpace}>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteePhoneIcon}
                                          alt="Placement Committee"
                                        />
                                        <h6>+91 9975180442</h6>
                                      </div>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteeMailIcon}
                                          alt="Placement Committee"
                                        />
                                        <div className={styles.LeftSpace}>
                                          <h6>g22063@astra.xlri.ac.in</h6>
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className={styles.foundingBgTxt}>
                                  <div className={styles.foundingImgWrapper}>
                                    <img src={studProfile} alt="home img" />
                                  </div>
                                  <div className={styles.TextCls}>
                                    <a
                                      href="https://www.linkedin.com/in/gagan-a-bajaj"
                                      target="_blank"
                                    >
                                      <div className={styles.LinkedImgStyle}>
                                        <img
                                          src={LinkedInImg}
                                          alt="About-slider"
                                        />
                                      </div>
                                    </a>
                                    <h2>Gagan Bajaj</h2>
                                    <div className={styles.CardSpace}>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteePhoneIcon}
                                          alt="Placement Committee"
                                        />
                                        <h6>+91 8087125009</h6>
                                      </div>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteeMailIcon}
                                          alt="Placement Committee"
                                        />
                                        <div className={styles.LeftSpace}>
                                          <h6>g22018@astra.xlri.ac.in</h6>
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className={styles.foundingBgTxt}>
                                  <div className={styles.foundingImgWrapper}>
                                    <img src={studProfile} alt="home img" />
                                  </div>
                                  <div className={styles.TextCls}>
                                    <a
                                      href="https://www.linkedin.com/in/saiprasanth-bandaru"
                                      target="_blank"
                                    >
                                      <div className={styles.LinkedImgStyle}>
                                        <img
                                          src={LinkedInImg}
                                          alt="About-slider"
                                        />
                                      </div>
                                    </a>
                                    <h2>
                                      Bandaru Kanaka Siva Ram Sai Prasanth
                                    </h2>
                                    <div className={styles.CardSpace}>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteePhoneIcon}
                                          alt="Placement Committee"
                                        />
                                        <h6>+91 9603548663</h6>
                                      </div>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteeMailIcon}
                                          alt="Placement Committee"
                                        />
                                        <div className={styles.LeftSpace}>
                                          <h6>g22092@astra.xlri.ac.in</h6>
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className={styles.foundingBgTxt}>
                                  <div className={styles.foundingImgWrapper}>
                                    <img src={studProfile} alt="home img" />
                                  </div>
                                  <div className={styles.TextCls}>
                                    <a
                                      href="https://www.linkedin.com/in/aparajita-chaudhuri"
                                      target="_blank"
                                    >
                                      <div className={styles.LinkedImgStyle}>
                                        <img
                                          src={LinkedInImg}
                                          alt="About-slider"
                                        />
                                      </div>
                                    </a>
                                    <h2>Aparajita Chaudhuri</h2>
                                    <div className={styles.CardSpace}>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteePhoneIcon}
                                          alt="Placement Committee"
                                        />
                                        <h6>+91 7397310182</h6>
                                      </div>
                                      <div className={styles.DetailWrap}>
                                        <img
                                          src={PlacementCommitteeMailIcon}
                                          alt="Placement Committee"
                                        />
                                        <div className={styles.LeftSpace}>
                                          <h6>g22065@astra.xlri.ac.in</h6>
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          {/* <div className={styles.LoadStyle}>
                            <a className="linkWrapper" href="/">
                              <span className="a-18">Load More</span>
                              <Icon
                                icon="arrow-right-with-cut"
                                size={10}
                                color="#B2C950"
                              />
                            </a>
                          </div> */}
                        </div>
                      </section>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade "
                    id="nav-profile-two"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab-two"
                    tabIndex="0"
                  >
                    <h3>Profile</h3>
                    <p>
                      The PGDM (GM) batch consists of driven individuals with a
                      minimum of 3 years of experience representing diverse
                      backgrounds, cultures and functions with a will to thrive
                      and evolve. Many individuals have excelled in industry
                      roles with work experience in top global organisations.
                      The new learning initiatives promote innovation and build
                      on diversity and depth of each of the individuals,
                      ultimately adding to their cognitive content and
                      developing a resilient reaction to the current strenuous
                      season. The individuals learn to represent and work with
                      people from a blend of demographics while adding his or
                      her unique perspectives and learnings to the rich
                      knowledge base. A vigorous weave of people with rich work
                      experiences in diverse fields such as IT, Manufacturing &
                      Engineering, Energy, E-commerce, Education, Retail,
                      Shipping, Automobile, Banking, Petroleum, Medical
                      Sciences, Aviation and Government services make academic
                      interactions and projects even more stimulating.
                    </p>
                    <p>
                      The snapshot below showcases a summary of the 93 students’
                      batch; among whom, 85 will be participating in campus
                      placements while eight have opted out. Admission is
                      through a minimum of 5 years of Professional Experience,
                      Academic record, GMAT/ XAT scores, Essays and Personal
                      Interviews. This rigorous program offers an excellent
                      opportunity for professionals to sharpen their business
                      acumen in a school with one of the best academic
                      infrastructure and environment in India - XLRI Jamshedpur.
                    </p>
                    <h3>2023-24 Batch Statistics</h3>
                    <h4>2023-24 Batch Profile</h4>
                    <div className={`${styles.tableProfile}`}>
                      <table>
                        <thead>
                          <tr>
                            <th>Class Composition</th>
                            <th>Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Batch Size</td>
                            <td>114</td>
                          </tr>
                          <tr>
                            <td>Average Age</td>
                            <td>29</td>
                          </tr>
                          <tr>
                            <td>Average Work Experience</td>
                            <td>71 months</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="mt-5">
                      <img
                        src={BatchProfile}
                        alt="Batch Profile PGDGM"
                        className="w-100"
                      />
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="nav-vil-two"
                    role="tabpanel"
                    aria-labelledby="nav-vil-query-two"
                    tabIndex="0"
                  >
                    <h3>Admission Procedure</h3>
                    <p>
                      The future won’t belong to ‘Employees’, but to those who
                      take charge. Discover the leader within and get a second-
                      wind in your career.
                    </p>
                    <p>
                      The PGDM (GM) (General) Programme aims at augmenting the
                      skill-sets and competencies of practicing managers.
                      Exposing them to recent trends, tools and techniques in
                      Management would not only provide them with extra skills
                      sets but also help them reflect upon the previous
                      experience of working in organizations. Though the program
                      offers concentration in conventional subjects like
                      Economics, Finance, Information Systems, Marketing,
                      Production Management and Strategic Management but the
                      focus of program is to prepare current managers for the
                      leadership roles. This 18 month, full time AICTE
                      recognized course incorporates a 3-month field based
                      training in a functional area.
                    </p>
                    <h3>Eligibility</h3>
                    <p>
                      Candidates must possess a recognized bachelor’s degree of
                      at least three years in any discipline. Additionally, they
                      should have a minimum of three years of managerial or
                      supervisory experience by March 31, 2025. Applicants may
                      submit GMAT or GRE scores taken between December 1, 2021,
                      and December 31, 2024. Please note that XAT 2025 scores
                      will also be accepted for this program. However, the XAT
                      exam is scheduled for January 5, 2025, and requires a
                      separate registration.
                    </p>
                    <div className="row">
                      <div className="col-xl-12">
                        <div
                          className={`mt-1 d-xl-flex d-block  ${styles.ButtonWrap}`}
                        >
                          <button
                            className={`btnwrap ${styles.BtnColorBlue} ${styles.BtnNewSpace}`}
                          >
                            <span className="a-18">
                              <a href="https://xatonline.in/" target="_blank">
                                Register for XAT & XLRI
                              </a>
                            </span>
                            <Icon
                              icon="arrow-right-with-cut"
                              size={10}
                              color="#B2C950"
                            />
                          </button>
                          <button
                            className={`btnwrap ${styles.BtnColorBlue} ${styles.BtnNewSpace}`}
                          >
                            <span className="a-18">
                              <a
                                href="https://xatonline.in/xlri"
                                target="_blank"
                              >
                                Register through GMAT/GRE
                              </a>
                            </span>
                            <Icon
                              icon="arrow-right-with-cut"
                              size={10}
                              color="#B2C950"
                            />
                          </button>
                        </div>
                        <br />
                      </div>
                    </div>
                    <h3>Selection Criteria</h3>
                    <p>
                      Candidates may apply for more than one programme. Those
                      who apply for multiple programmes may be called for
                      multiple interviews and/or group discussions. The
                      selection criteria for different programmes may differ
                      depending on the nature of the programme. XAT written test
                      will have multiple components and you need to maximize
                      your performance in each of these components. In its
                      pursuit of academic excellence, XLRI actively encourages
                      the students from diverse backgrounds. While preparing the
                      shortlisted candidates for the interview, in addition to
                      XAT performance, we may factor in academic ability and the
                      relevant work experience. Besides performance in the
                      personal interviews (and/or group discussions) we also
                      consider XAT performance, relevant work experience,
                      academic ability, extra-curricular activities etc. while
                      preparing the final list. For candidates who cannot be
                      present physically for the XLRI interviews due to their
                      being out of the country, interviews would be carried out
                      in online mode via Internet only for PGDM (GM).
                    </p>
                    <p>
                      The last date to apply for GMP through XAT is November 30,
                      2024 and through GMAT/GRE is December 31, 2024.
                    </p>
                    <p>
                      The interviews for GMP will be conducted in multiple
                      rounds.
                      <br />
                      Round 1 : Between November and December
                      <br />
                      Round 2 : Between January and February
                    </p>
                    <p>The programme will commence in Mid April.</p>
                    <p className={styles.cautionStatement}>
                      <span>
                        Note: Candidates may please note that the tentative
                        dates for interviews are likely to be scheduled during
                        February 16 to March 15, 2025.
                      </span>
                    </p>

                    <section className={styles.MainBoxStyle}>
                      <div className="row">
                        <div
                          className={`col-12 col-md-6 col-xl-3 ${styles.BoxSec}`}
                        >
                          <div
                            className={`${styles.DarkBlue} ${styles.BoxNew}`}
                          >
                            <div className={styles.BorderStyle}>
                              <h4>Stage 01</h4>
                              <p>Applying through GMAT/GRE/XAT</p>
                            </div>
                            <div className={styles.IndustryAdvisoryBulletNav}>
                              <h6>
                                Prerequisites for GMAT and GRE Applicants:
                              </h6>
                              <p>
                                All GMAT and GRE applicants must take their GMAT
                                and GRE between December 01, 2021 and December
                                31, 2024.
                              </p>
                              <p>
                                Indian candidate registering through XAT 2025
                                Rs. 2200/- and Rs. 200/- each for every
                                additional programme of XLRI
                              </p>
                              <p>
                                Foreign and NRI candidates registering through
                                XAT 2025 Rs. 5000/- [For one or more
                                programmes(s)]
                              </p>
                              <p>
                                Indian candidate through GMAT / GRE Rs. 2500/-
                                [Only for PGDM (GM)]
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`col-12 col-md-6 col-xl-3 ${styles.BoxSec}`}
                        >
                          <div
                            className={`${styles.LightBlue} ${styles.BoxNew}`}
                          >
                            <div className={styles.BorderStyleTwo}>
                              <h4>Stage 02</h4>
                              <p>Shortlist of Candidates</p>
                            </div>
                            <div className={styles.IndustryAdvisoryBulletNav}>
                              <p>
                                The names of the XAT/ GMAT / GRE candidates will
                                be shortlisted by the second week of January
                              </p>
                              <p>
                                Round 1 results will be announced by end of
                                December
                              </p>
                              <p>
                                Round 2 results will be announced in the month
                                of February
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`col-12 col-md-6 col-xl-3 ${styles.BoxSec}`}
                        >
                          <div className={`${styles.Green} ${styles.BoxNew}`}>
                            <div className={styles.BorderStyleThree}>
                              <h4>Stage 03</h4>
                              <p>Interview Process</p>
                            </div>
                            <div className={styles.IndustryAdvisoryBulletNav}>
                              <p>
                                The interviews for PGDM (GM) candidates through
                                XAT, GMAT and GRE will be conducted online by
                                end of January on Zoom platform.
                              </p>
                              <p>
                                PGDM (GM) Interviews can be conducted over
                                G-talk / Skype for overseas candidates.
                                Candidate needs to get in touch with the
                                admissions office for scheduling the interview
                                online
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`col-12 col-md-6 col-xl-3 ${styles.BoxSec}`}
                        >
                          <div className={`${styles.Black} ${styles.BoxNew}`}>
                            <div className={styles.BorderStyleThree}>
                              <h4>Stage 04</h4>
                              <p>Result Announcement</p>
                            </div>
                            <div className={styles.IndustryAdvisoryBulletNav}>
                              <p>
                                The short-listed candidates will be intimated
                                via email along with an offer letter for
                                XLRI-PGDM (GM)
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    <h3>Fees</h3>
                    <p>
                      Fees and other charges for 18-month PGDM (GM) will be
                      approximately Rs. 24.20 lakh (or equivalent in $ US) for
                      the academic year 2024 - 2025. Food, electricity and other
                      personal expenses would be charged extra depending on the
                      type of accommodation preferred by the student. Fee may
                      change next session based on the recommendation of Fee
                      Fixation Committee.
                    </p>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="nav-vil-three"
                    role="tabpanel"
                    aria-labelledby="nav-vil-query-three"
                    tabIndex="0"
                  >
                    ...
                  </div>
                </div>
              </div>

              <div className={styles.tabMainSecMobile}>
                <div className={styles.researchAccordionBgSec1}>
                  <div
                    className="accordion accordionResearch"
                    id="accordionExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOneIn">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOneTwo"
                          aria-expanded="true"
                          aria-controls="collapseOneTwo"
                        >
                          {" "}
                          Academics
                        </button>
                      </h2>
                      <div
                        id="collapseOneTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOneTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <h5>Curriculum</h5>
                        <p>
                          This year the MBA program comprises of seven terms
                          with duration of all terms ranges between 5 to 13
                          weeks depending on number of subjects in each term.
                          Every term ends with an exam. The first 3 Terms are
                          broadly focused on to build rudimentary management
                          concepts across all disciplines. The 15 core courses
                          offered during this time includes subjects from
                          finance, economics, marketing, operations, strategy,
                          business ethics and sustainable development. The
                          remaining Terms of the program can be customized,
                          allowing you to choose approximately 20 different
                          electives that support your career and learning
                          objectives. XLRI offers a rich and varied choice of
                          electives for you to choose from - over 53 altogether.
                        </p>
                        <h5>Pre-term</h5>
                        <p>
                          Three days of introductory course, which lay the
                          foundation for all courses to be, is taught during the
                          18 month full-time MBA. The objective of the pre-term
                          is to normalize students coming from different
                          backgrounds.
                        </p>
                        <img
                          src={studentImage2}
                          alt="Postgraduate diploma in General Management (PGDGM)"
                        />
                        <h5>Term-wise break-up</h5>
                        <div className={`row ${styles.termWiseMain}`}>
                          <div className={`col-lg-12 ${styles.termWiseSub}`}>
                            <div className={styles.termWiseContent}>
                              <h5>
                                Term <strong>01</strong>
                              </h5>
                              <ul>
                                <li>
                                  <span>Marketing Management-I</span>
                                  <span>3</span>
                                </li>
                                <li>
                                  <span>Managerial Communication-I</span>
                                  <span>3</span>
                                </li>
                                <li>
                                  <span>Managerial Economics</span>
                                  <span>2.5</span>
                                </li>
                                <li>
                                  <span>
                                    Managing Human Behaviour at Work (OB-I)
                                  </span>
                                  <span>2</span>
                                </li>
                                <li>
                                  <span>
                                    Quantitative Techniques for Managerial
                                    Decisions-I
                                  </span>
                                  <span>2</span>
                                </li>
                                <li>
                                  <span>Legal Environment of Business</span>
                                  <span>2.5</span>
                                </li>
                                <li>
                                  <span>Marketing Management-I</span>
                                  <span>2</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className={`col-lg-12 ${styles.termWiseSub}`}>
                            <div className={styles.termWiseContent}>
                              <h5>
                                Term <strong>02</strong>
                              </h5>
                              <ul>
                                <li>
                                  <span>Marketing Management-II</span>
                                  <span>2</span>
                                </li>
                                <li>
                                  <span>Macroeconomic Theory and Policy</span>
                                  <span>2.5</span>
                                </li>
                                <li>
                                  <span>Financial Management-I</span>
                                  <span>2</span>
                                </li>
                                <li>
                                  <span>Human Resource Management</span>
                                  <span>2</span>
                                </li>
                                <li>
                                  <span>Management Accounting-II</span>
                                  <span>2</span>
                                </li>
                                <li>
                                  <span>
                                    Managing Human Behaviour at Work (OB-II)
                                  </span>
                                  <span>2</span>
                                </li>
                                <li>
                                  <span>Competitive Strategy</span>
                                  <span>2</span>
                                </li>
                                <li>
                                  <span>
                                    Quantitative Techniques for Managerial
                                    Decisions-II
                                  </span>
                                  <span>2</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className={`col-lg-12 ${styles.termWiseSub}`}>
                            <div className={styles.termWiseContent}>
                              <h5>
                                Term <strong>03</strong>
                              </h5>
                              <ul>
                                <li>
                                  <span>Operations Research</span>
                                  <span>2</span>
                                </li>
                                <li>
                                  <span>Managerial Ethics2</span>
                                  <span>2</span>
                                </li>
                                <li>
                                  <span>
                                    MIS: Technical and Social
                                    Perspectives(Includes Spreadsheet Modeling )
                                  </span>
                                  <span>3</span>
                                </li>
                                <li>
                                  <span>
                                    Corporate Strategy and Strategy
                                    Implementation
                                  </span>
                                  <span>3</span>
                                </li>
                                <li>
                                  <span>Managerial Communication-II</span>
                                  <span>1.5</span>
                                </li>
                                <li>
                                  <span>Industrial Relations</span>
                                  <span>2</span>
                                </li>
                                <li>
                                  <span>
                                    Production and Operations Management-I
                                  </span>
                                  <span>2</span>
                                </li>
                                <li>
                                  <span>Financial Management-II</span>
                                  <span>2</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className={`col-lg-12 ${styles.termWiseSub}`}>
                            <div className={styles.termWiseContent}>
                              <h5>
                                Term <strong>04</strong>
                              </h5>
                              <ul>
                                <li>
                                  <span>
                                    Production and Operations Management-II
                                  </span>
                                  <span>2</span>
                                </li>
                                <li>
                                  <span>Digital transformation</span>
                                  <span>1.5</span>
                                </li>
                                <li>
                                  <span>
                                    Introduction to Sustainable Development &
                                    Corporate Sustainability
                                  </span>
                                  <span>2</span>
                                </li>
                                <li>
                                  <span>Electives</span>
                                  <span>6</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <h5>Electives will be offered in Term V & Term VI</h5>
                        <h3>List of Electives Offered</h3>
                        <div className={styles.tableMain}>
                          <table>
                            <thead>
                              <tr>
                                <th>General Management</th>
                                <th>General Management</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Communicating Critical Decisions</td>
                                <td>Strategies of Cooperation</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className={styles.tableMain}>
                          <table>
                            <thead>
                              <tr>
                                <th>Strategic Management</th>
                                <th>Information Systems</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Balanced Scorecard</td>
                                <td>Big Data Analytics</td>
                              </tr>
                              <tr>
                                <td>Balanced Scorecard</td>
                                <td>Big Data Analytics</td>
                              </tr>
                              <tr>
                                <td>Entrepreneurship and New Ventures</td>
                                <td>Business Intelligence and Data Mining</td>
                              </tr>
                              <tr>
                                <td>
                                  Foundations of Management Consulting Practice
                                </td>
                                <td>Business Modelling Using Spreadsheets</td>
                              </tr>
                              <tr>
                                <td>International Management</td>
                                <td>IS Strategy</td>
                              </tr>
                              <tr>
                                <td>Managing Innovation</td>
                                <td>
                                  Social Mobile Analytics and Cloud - The
                                  Emerging Business Accelerator
                                </td>
                              </tr>
                              <tr>
                                <td>Managing Strategic Transformation</td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className={styles.tableMain}>
                          <table>
                            <thead>
                              <tr>
                                <th>
                                  Production, Operations & Decision Sciences
                                </th>
                                <th>Finance</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Demand Forecasting</td>
                                <td>Advanced Corporate Finance</td>
                              </tr>
                              <tr>
                                <td>
                                  Introduction to Enterprise Resource Planning
                                </td>
                                <td>Business Analysis and Valuation</td>
                              </tr>
                              <tr>
                                <td>Introducing Supply Chain Analytics</td>
                                <td>
                                  Capital Expenditure Planning and Control
                                </td>
                              </tr>
                              <tr>
                                <td>Operations Planning and Control</td>
                                <td>Financial Modelling Using Excel</td>
                              </tr>
                              <tr>
                                <td>Project Planning and Control</td>
                                <td>Financial Risk Management</td>
                              </tr>
                              <tr>
                                <td>Quality Management</td>
                                <td>International Financial Management</td>
                              </tr>
                              <tr>
                                <td>Quality Management</td>
                                <td>Investments</td>
                              </tr>
                              <tr>
                                <td>Supply Chain Management</td>
                                <td>
                                  Mergers, Acquisition and Corporate
                                  Restructuring
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>Strategic Cost Management</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className={styles.tableMain}>
                          <table>
                            <thead>
                              <tr>
                                <th>Human Resource Management</th>
                                <th>Organization Behaviour</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>HR Issues in Mergers and Acquisitions</td>
                                <td>
                                  Managing Competencies and Performance in
                                  Organisations
                                </td>
                              </tr>
                              <tr>
                                <td>Performance Management and Appraisal</td>
                                <td>
                                  Thriving in Political Environment [With
                                  Integrity & Ethics]
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Tools and Techniques for Executive Development
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>Compensation and Reward Management</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>Balanced Scorecard</td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className={styles.tableMain}>
                          <table>
                            <thead>
                              <tr>
                                <th>Marketing</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  Advertising and Integrated Brand Promotion
                                </td>
                                <td>Business-to-Business Marketing</td>
                              </tr>
                              <tr>
                                <td>Consumer Behaviour</td>
                                <td>Competition and Globalization</td>
                              </tr>
                              <tr>
                                <td>Entrepreneurship and New Ventures</td>
                                <td>
                                  Experiential Workshop on Selling and
                                  Negotiating Tactics
                                </td>
                              </tr>
                              <tr>
                                <td>Internet Marketing</td>
                                <td>Managing Corporate Reputation</td>
                              </tr>
                              <tr>
                                <td>Marketing Analytics</td>
                                <td>Marketing Strategy and Implementation</td>
                              </tr>
                              <tr>
                                <td>Product and Brand Management</td>
                                <td>Retail Management</td>
                              </tr>
                              <tr>
                                <td>Sales and Distribution Management</td>
                                <td>Services Marketing Management</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <h3>Leadership & Adventure</h3>
                        <p>
                          The essence of the adventure trip is to experience the
                          thrill and power of team spirit. This fun trip deepens
                          the belief in team power. Students experience a rush
                          of adrenalin and the two and half days can be defined
                          as fun and frolic. The trip tests a student’s
                          physical, mental, strategy building, resource/energy
                          management and team building skills. This program
                          helps one understand his/her limits and then go way
                          past them. The philosophy of stretching beyond the
                          limit is what XLRI preaches. XLRI’s methodology of
                          teaching, which is about reinventing oneself
                          continuously, is put to practice in this trip.
                        </p>

                        <h3>Rural Exposure</h3>
                        <p>
                          The intent of this program is to train students to
                          understand the problems of rural India and address
                          them in a better way, it is mandatory for students to
                          spend a few days in the rural parts of India.
                        </p>
                        <p>
                          Under the Village Exposure Program, students of the
                          PGDM (GM) have to identify the problems of the people
                          of a particular village and then suggest solutions.
                          Students will then have to design business modules.
                          The rural exposure Program will help the future
                          managers to understand the needs of the local people
                          and provide solutions to their problems.
                        </p>
                        <div className={styles.ambassdorContainer}>
                          <section className={styles.CardSection}>
                            <div className="container">
                              <h1>Campus Ambassadors</h1>
                              <div className={styles.SliderContent}>
                                <ul>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/simarjeet1/"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Simarjeet Singh Dua</h2>

                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 9891311132</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22043@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/surbhitp"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Surbhit Parandiyal</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 8093676974</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>G22108@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/1ambharath"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Bharath R</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 7696610609</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>G22013@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/sanjeevani-312887121"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Sanjeevani</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 8545957829</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>G22036@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/amit-sunar"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Amit Sunar</h2>

                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 9643889181</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22004@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/kajalkumari04/"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Kajal Kumari</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 7873709563</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22022@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/sumit-mudgal-ba0228a0/"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Sumit Mudgal</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 8800335407</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22106@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/vivek-bhagat-bb1685ab"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Vivek Kumar</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 8750974630</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22112@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/nishit-rastogi-b751b3180/"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Nishit Rastogi</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 8460824002</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22028@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/dilpreet-kaur-112090145/"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Dilpreet Kaur</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 8447541994</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22073@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/vijayankesh-bariar"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Vijayankesh Barier</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 8116366695</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22054@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/jeetu-mohan-926ab020/"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Jeetu Mohan</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 8007446664</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22078@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/pooja-mahindrakar"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Pooja Mahindrakar</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 7016774168</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22030@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/akshayrmali"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Akshay Mali</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 9085293321</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22061@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/jyoti-panda"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Jyoti Panda</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 7749842607</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22079@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/kumgoutam"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Kumar Goutam</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 9900486995</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22023@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/ankeet-rai"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Ankeet Rai</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 9975180442</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22063@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/gagan-a-bajaj"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Gagan Bajaj</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 8087125009</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22018@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/saiprasanth-bandaru"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>
                                          Bandaru Kanaka Siva Ram Sai Prasanth
                                        </h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 9603548663</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22092@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className={styles.foundingBgTxt}>
                                      <div
                                        className={styles.foundingImgWrapper}
                                      >
                                        <img src={studProfile} alt="home img" />
                                      </div>
                                      <div className={styles.TextCls}>
                                        <a
                                          href="https://www.linkedin.com/in/aparajita-chaudhuri"
                                          target="_blank"
                                        >
                                          <div
                                            className={styles.LinkedImgStyle}
                                          >
                                            <img
                                              src={LinkedInImg}
                                              alt="About-slider"
                                            />
                                          </div>
                                        </a>
                                        <h2>Aparajita Chaudhuri</h2>
                                        <div className={styles.CardSpace}>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteePhoneIcon}
                                              alt="Placement Committee"
                                            />
                                            <h6>+91 7397310182</h6>
                                          </div>
                                          <div className={styles.DetailWrap}>
                                            <img
                                              src={PlacementCommitteeMailIcon}
                                              alt="Placement Committee"
                                            />
                                            <div className={styles.LeftSpace}>
                                              <h6>g22065@astra.xlri.ac.in</h6>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              {/* <div className={styles.LoadStyle}>
                            <a className="linkWrapper" href="/">
                              <span className="a-18">Load More</span>
                              <Icon
                                icon="arrow-right-with-cut"
                                size={10}
                                color="#B2C950"
                              />
                            </a>
                          </div> */}
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwoTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwoTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwoTwo"
                        >
                          Profile
                        </button>
                      </h2>
                      <div
                        id="collapseTwoTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwoTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <h3>Profile</h3>
                          <p>
                            The PGDM (GM) batch consists of driven individuals
                            with a minimum of 3 years of experience representing
                            diverse backgrounds, cultures and functions with a
                            will to thrive and evolve. Many individuals have
                            excelled in industry roles with work experience in
                            top global organisations. The new learning
                            initiatives promote innovation and build on
                            diversity and depth of each of the individuals,
                            ultimately adding to their cognitive content and
                            developing a resilient reaction to the current
                            strenuous season. The individuals learn to represent
                            and work with people from a blend of demographics
                            while adding his or her unique perspectives and
                            learnings to the rich knowledge base. A vigorous
                            weave of people with rich work experiences in
                            diverse fields such as IT, Manufacturing &
                            Engineering, Energy, E-commerce, Education, Retail,
                            Shipping, Automobile, Banking, Petroleum, Medical
                            Sciences, Aviation and Government services make
                            academic interactions and projects even more
                            stimulating.
                          </p>
                          <p>
                            The snapshot below showcases a summary of the 93
                            students’ batch; among whom, 85 will be
                            participating in campus placements while eight have
                            opted out. Admission is through a minimum of 5 years
                            of Professional Experience, Academic record, GMAT/
                            XAT scores, Essays and Personal Interviews. This
                            rigorous program offers an excellent opportunity for
                            professionals to sharpen their business acumen in a
                            school with one of the best academic infrastructure
                            and environment in India - XLRI Jamshedpur.
                          </p>
                          <h3>2023-24 Batch Statistics</h3>
                          <h4>2023-24 Batch Profile</h4>
                          <div className={`${styles.tableProfile}`}>
                            <table>
                              <thead>
                                <tr>
                                  <th>Class Composition</th>
                                  <th>Value</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Batch Size</td>
                                  <td>114</td>
                                </tr>
                                <tr>
                                  <td>Average Age</td>
                                  <td>29</td>
                                </tr>
                                <tr>
                                  <td>Average Work Experience</td>
                                  <td>71 months</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="mt-5">
                            <img
                              src={BatchProfile}
                              alt="Batch Profile PGDGM"
                              className="w-100"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThreeTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThreeTwo"
                          aria-expanded="false"
                          aria-controls="collapseThreeTwo"
                        >
                          Admission Procedure
                        </button>
                      </h2>
                      <div
                        id="collapseThreeTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThreeTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <h3>Admission Procedure</h3>
                        <p>
                          The future won’t belong to ‘Employees’, but to those
                          who take charge. Discover the leader within and get a
                          second- wind in your career.
                        </p>
                        <p>
                          The PGDM (GM) (General) Programme aims at augmenting
                          the skill-sets and competencies of practicing
                          managers. Exposing them to recent trends, tools and
                          techniques in Management would not only provide them
                          with extra skills sets but also help them reflect upon
                          the previous experience of working in organizations.
                          Though the program offers concentration in
                          conventional subjects like Economics, Finance,
                          Information Systems, Marketing, Production Management
                          and Strategic Management but the focus of program is
                          to prepare current managers for the leadership roles.
                          This 18 month, full time AICTE recognized course
                          incorporates a 3-month field based training in a
                          functional area.
                        </p>
                        <h3>Eligibility</h3>
                        <p>
                          Candidates must possess a recognized bachelor’s degree
                          of at least three years in any discipline.
                          Additionally, they should have a minimum of three
                          years of managerial or supervisory experience by March
                          31, 2025. Applicants may submit GMAT or GRE scores
                          taken between December 1, 2021, and December 31, 2024.
                          Please note that XAT 2025 scores will also be accepted
                          for this program. However, the XAT exam is scheduled
                          for January 5, 2025, and requires a separate
                          registration.
                        </p>
                        <div className="row">
                          <div className="col-xl-12">
                            <div
                              className={`mt-1 d-xl-flex d-block  ${styles.ButtonWrap}`}
                            >
                              <button
                                className={`btnwrap ${styles.BtnColorBlue} ${styles.BtnNewSpace}`}
                              >
                                <span className="a-18">
                                  <a
                                    href="https://xatonline.in/"
                                    target="_blank"
                                  >
                                    Register for XAT & XLRI
                                  </a>
                                </span>
                                <Icon
                                  icon="arrow-right-with-cut"
                                  size={10}
                                  color="#B2C950"
                                />
                              </button>
                              <button
                                className={`btnwrap ${styles.BtnColorBlue} ${styles.BtnNewSpace}`}
                              >
                                <span className="a-18">
                                  <a
                                    href="https://xatonline.in/xlri"
                                    target="_blank"
                                  >
                                    Register through GMAT/GRE
                                  </a>
                                </span>
                                <Icon
                                  icon="arrow-right-with-cut"
                                  size={10}
                                  color="#B2C950"
                                />
                              </button>
                            </div>
                            <br />
                          </div>
                        </div>
                        <h3>Selection Criteria</h3>
                        <p>
                          Candidates may apply for more than one programme.
                          Those who apply for multiple programmes may be called
                          for multiple interviews and/or group discussions. The
                          selection criteria for different programmes may differ
                          depending on the nature of the programme. XAT written
                          test will have multiple components and you need to
                          maximize your performance in each of these components.
                          In its pursuit of academic excellence, XLRI actively
                          encourages the students from diverse backgrounds.
                          While preparing the shortlisted candidates for the
                          interview, in addition to XAT performance, we may
                          factor in academic ability and the relevant work
                          experience. Besides performance in the personal
                          interviews (and/or group discussions) we also consider
                          XAT performance, relevant work experience, academic
                          ability, extra-curricular activities etc. while
                          preparing the final list. For candidates who cannot be
                          present physically for the XLRI interviews due to
                          their being out of the country, interviews would be
                          carried out in online mode via Internet only for PGDM
                          (GM).
                        </p>
                        <p>
                          The last date to apply for GMP through XAT is November
                          30, 2024 and through GMAT/GRE is December 31, 2024.
                        </p>
                        <p>
                          The interviews for GMP will be conducted in multiple
                          rounds.
                          <br />
                          Round 1 : Between November and December
                          <br />
                          Round 2 : Between January and February
                        </p>
                        <p>The programme will commence in Mid April.</p>
                        <p className={styles.cautionStatement}>
                          <span>
                            Note: Candidates may please note that the tentative
                            dates for interviews are likely to be scheduled
                            during February 16 to March 15, 2025.
                          </span>
                        </p>

                        <section className={styles.MainBoxStyle}>
                          <div className="row">
                            <div
                              className={`col-12 col-md-6 col-xl-3 ${styles.BoxSec}`}
                            >
                              <div
                                className={`${styles.DarkBlue} ${styles.BoxNew}`}
                              >
                                <div className={styles.BorderStyle}>
                                  <h4>Stage 01</h4>
                                  <p>Applying through GMAT/GRE/XAT</p>
                                </div>
                                <div
                                  className={styles.IndustryAdvisoryBulletNav}
                                >
                                  <h6>
                                    Prerequisites for GMAT and GRE Applicants:
                                  </h6>
                                  <p>
                                    All GMAT and GRE applicants must take their
                                    GMAT and GRE between December 01, 2021 and
                                    December 31, 2024.
                                  </p>
                                  <p>
                                    Indian candidate registering through XAT
                                    2025 Rs. 2200/- and Rs. 200/- each for every
                                    additional programme of XLRI
                                  </p>
                                  <p>
                                    Foreign and NRI candidates registering
                                    through XAT 2025 Rs. 5000/- [For one or more
                                    programmes(s)]
                                  </p>
                                  <p>
                                    Indian candidate through GMAT / GRE Rs.
                                    2500/- [Only for PGDM (GM)]
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`col-12 col-md-6 col-xl-3 ${styles.BoxSec}`}
                            >
                              <div
                                className={`${styles.LightBlue} ${styles.BoxNew}`}
                              >
                                <div className={styles.BorderStyleTwo}>
                                  <h4>Stage 02</h4>
                                  <p>Shortlist of Candidates</p>
                                </div>
                                <div
                                  className={styles.IndustryAdvisoryBulletNav}
                                >
                                  <p>
                                    The names of the XAT/ GMAT / GRE candidates
                                    will be shortlisted by the second week of
                                    January
                                  </p>
                                  <p>
                                    Round 1 results will be announced by end of
                                    December
                                  </p>
                                  <p>
                                    Round 2 results will be announced in the
                                    month of February
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`col-12 col-md-6 col-xl-3 ${styles.BoxSec}`}
                            >
                              <div
                                className={`${styles.Green} ${styles.BoxNew}`}
                              >
                                <div className={styles.BorderStyleThree}>
                                  <h4>Stage 03</h4>
                                  <p>Interview Process</p>
                                </div>
                                <div
                                  className={styles.IndustryAdvisoryBulletNav}
                                >
                                  <p>
                                    The interviews for PGDM (GM) candidates
                                    through XAT, GMAT and GRE will be conducted
                                    online by end of January on Zoom platform.
                                  </p>
                                  <p>
                                    PGDM (GM) Interviews can be conducted over
                                    G-talk / Skype for overseas candidates.
                                    Candidate needs to get in touch with the
                                    admissions office for scheduling the
                                    interview online
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`col-12 col-md-6 col-xl-3 ${styles.BoxSec}`}
                            >
                              <div
                                className={`${styles.Black} ${styles.BoxNew}`}
                              >
                                <div className={styles.BorderStyleThree}>
                                  <h4>Stage 04</h4>
                                  <p>Result Announcement</p>
                                </div>
                                <div
                                  className={styles.IndustryAdvisoryBulletNav}
                                >
                                  <p>
                                    The short-listed candidates will be
                                    intimated via email along with an offer
                                    letter for XLRI-PGDM (GM)
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>

                        <h3>Fees</h3>
                        <p>
                          Fees and other charges for 18-month PGDM (GM) will be
                          approximately Rs. 24.20 lakh (or equivalent in $ US)
                          for the academic year 2024 - 2025. Food, electricity
                          and other personal expenses would be charged extra
                          depending on the type of accommodation preferred by
                          the student. Fee may change next session based on the
                          recommendation of Fee Fixation Committee.
                        </p>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFourThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFourThree"
                          aria-expanded="false"
                          aria-controls="collapseFourThree"
                          onClick={() => navigateToPlacement()}
                        >
                          Placement
                        </button>
                      </h2>
                      <div
                        id="collapseFourThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFourThree"
                        data-bs-parent="#accordionExample"
                      >
                        .....
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5">
        {" "}
        <RelatedCourses />
      </div>
    </div>
  );
};

export default SchoolOfBusinessNew;
