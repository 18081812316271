import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const MetaData = ({ metaData }) => {
  const location = useLocation();
  const [item, setItem] = useState();
  useEffect(() => {
    const filtered = metaData?.find(
      (obj) => obj?.pageRoute === location?.pathname
    );
    if(filtered){
        setItem(filtered)
    }
  }, [location,metaData]);

  return (
    <div>
      <Helmet>
        <title>{item?.metaTitle}</title>
        <meta name="description" content={item?.metaDescription} />
      </Helmet>
    </div>
  );
};

export default MetaData;
